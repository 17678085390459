<template>
  <div id="box">
    <div id="from">
      <h2>书云心理健康登录系统</h2>
      <el-form ref="form" :model="user" label-width="80px">
        <el-input
          v-model="user.username"
          placeholder="请输入用户名"
          class="zh"
        ></el-input>
        <el-input
          v-model="user.password"
          placeholder="请输入密码"
          show-password
        ></el-input>
        <el-button type="primary" :loading="loading" @click="login"
          >登录</el-button
        >
        <el-link type="primary" @click="toRegister">没有账号？点击注册</el-link>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";

export default {
  data() {
    return {
      user: { username: "", password: "" },
      loading: false,
    };
  },
  methods: {
    async login() {
      const data = await api.login.login(this.user);
      // console.log("前端登录", data);
      if (data.data.data == true) {
        this.headers = data.headers;
        const token = data.data.token;
        const userData = data.data.user_name;
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("token", token);
        Message.success("登录成功");
        this.$router.replace("/System/Home");
      }
      if (data.data.message == "服务器连接失败！") {
        Message.error("服务器响应过慢，请等待或刷新页面");
      }
      if (data.data.data == false) {
        Message.error("用户名或者密码错误");
      }
    },
    toRegister() {
      this.$router.push("/Register");
    },
  },
};
</script>

<style lang='scss' scoped>
#box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: rgb(117, 212, 241);
  #from {
    width: 400px;
    height: 250px;
    padding: 20px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    h2 {
      margin: 20px;
      font-size: 24px;
    }
    .zh {
      margin-bottom: 25px;
    }
    button {
      margin-top: 25px;
    }
  }
}
</style>
